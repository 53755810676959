<template>
  <div
    :id="modalId"
    :class="classes"
    :style="this.styles"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog">
      <div v-if="data" class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{$t('adm.customer.edit-prod')}}</h5>
          <button type="button" class="close" @click="hide" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form @submit.prevent="save" class="form-horizontal">
          <div class="modal-body">
            <div class="form-body">
              <div class="form-group row">
                <label class="control-label text-right col-md-4 text-left-m"
                  >{{$tc('generic-str.name', 1)}}</label
                >
                <div class="col-md-6">
                  <input v-model="form.name" type="text" class="form-control" />
                </div>
              </div>
              <div class="form-group row">
                <label class="control-label text-right col-md-4 text-left-m"
                  >{{$t('sms.short-code.lbl-nickname')}}</label
                >
                <div class="col-md-6">
                  <input
                    v-model="form.alias"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="control-label text-right col-md-4 text-left-m"
                  >{{$t('generic-str.type')}}</label
                >
                <div class="col-md-6">
                  <select-input
                    v-model="form.type"
                    :options="types"
                    :clearable="false"
                    @input="changeType"
                  />
                </div>
              </div>
              <template v-if="form.values">
                <hr class="dashed" />
                <div
                  v-for="(value, product) in form.values"
                  :key="product"
                  class="form-group row"
                >
                  <label class="control-label text-right col-md-4 text-left-m">
                    Custo {{ product }}
                  </label>
                  <div class="col-md-6">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="0.0000"
                      v-model.lazy="form.values[product]"
                      v-money="money"
                      style="text-align: right"
                    />
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="hide">
              {{$t('generic-str.cancel')}}
            </button>
            <button
              :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }"
              :disabled="isSending"
              type="submit"
              class="btn btn-primary"
            >
              {{$t('generic-str.save')}}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ProviderService from '@/services/admin/provider.service';
import Modal from '@/mixins/Modal';
import SelectInput from '@/components/SelectInput.vue';

export default {
  mixins: [Modal],
  components: {
    SelectInput,
  },
  props: ['id'],
  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 4,
        masked: false,
      },
      types: [],
      routes: [],
      form: {
        name: '',
        alias: '',
        type: '',
        values: {},
      },
      isSending: false,
    };
  },
  created() {
    this.types = JSON.parse(process.env.VUE_APP_TYPES);
    this.form.type = this.types[0];
    this.changeType();
  },
  methods: {
    changeType() {
      if (this.form.type === 'SMS') {
        this.form.values = {
          Claro: 0,
          Nextel: 0,
          Oi: 0,
          Other: 0,
          Tim: 0,
          Vivo: 0,
        };
      } else if (this.form.type === 'EMAIL') {
        this.form.values = {
          Mensagem: 0,
          IP: 0,
        };
      } else if (this.form.type === 'WHATSAPP') {
        this.form.values = {
          Mensagem: 0,
        };
      } else if (this.form.type === 'RCS') {
        this.form.values = {
          'Claro Simples': 0,
          'Claro Sessão': 0,
          'Nextel Simples': 0,
          'Nextel Sessão': 0,
          'Oi Simples': 0,
          'Oi Sessão': 0,
          'Outras Simples': 0,
          'Outras Sessão': 0,
          'Tim Simples': 0,
          'Tim Sessão': 0,
          'Vivo Simples': 0,
          'Vivo Sessão': 0,
        };
      }
    },
    save() {
      this.isSending = true;
      ProviderService.updateProvider(this.form).then(
        () => {
          this.hide();
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: this.$t('adm-component.saved'),
            type: 'success',
          });
          this.$emit('sent');
          this.isSending = false;
        },
        (error) => {
          this.$toast.show({
            title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
            content: error.message,
            type: 'danger',
          });
          this.isSending = false;
        },
      );
    },
  },
};
</script>
