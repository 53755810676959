<template>
  <div class="content">
    <PageHeader :title="$tc('generic-str.menu.providers', 2)">
      <template v-slot:actions>
        <li>
          <router-link
            :to="'/admin/providers/create'"
            class="btn btn-success btn-floating"
          >
            <i
              class="
                fas fa-plus-circle
                text-white
                font-size-22
                v-align-text-bottom
              "
            ></i>
          </router-link>
        </li>
      </template>
    </PageHeader>
    <section class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body block-el p-0">
              <div v-if="fetched" class="responsive-table">
                <div
                  v-if="!providers.length && fetched"
                  class="text-center table-placeholder"
                >
                  <br />
                  <i class="fas fa-building font-size-80"></i>
                  <h5 class="card-title m-t-20">
                    {{$t('adm-component.none')}}
                  </h5>
                </div>
                <table class="table">
                  <thead class="bg-light" v-if="providers.length">
                    <tr>
                      <th scope="col" class="text-left">{{$tc('generic-str.name', 1)}}</th>
                      <th scope="col">{{$tc('live.channel', 2)}}</th>
                      <th scope="col">{{$t('generic-str.type')}}</th>
                      <th scope="col" class="text-right">{{$t('generic-str.actions')}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="provider in providers" :key="provider.id">
                      <td data-label="Nome" class="text-left">
                        {{ provider.name }}
                      </td>
                      <td data-label="Apelido">
                        <span v-if="provider.sms" class="col">
                          <i class="fas fa-sms fa-fw"></i>
                        </span>
                        <span v-if="provider.email" class="col">
                          <i class="fas fa-envelope-open fa-fw"></i>
                        </span>
                        <span v-if="provider.whatsapp" class="col">
                          <i class="fab fa-whatsapp fa-fw"></i>
                        </span>
                        <span v-if="provider.rcs" class="col">
                          <i class="fab fa-google fa-fw"></i>
                        </span>
                      </td>
                      <td scope="row" data-label="Tipo">
                        <span class="badge badge-pill badge-success">
                          {{ provider.type }}
                        </span>
                      </td>
                      <td class="action">
                        <div class="btn-group">
                          <router-link
                            :to="`/admin/providers/${provider.id}`"
                            class="btn btn-primary"
                          >
                            <span class="icon dripicons-pencil"></span>
                          </router-link>
                          <button
                            @click="remove(provider.id)"
                            type="button"
                            class="btn btn-danger"
                          >
                            <span class="icon dripicons-trash"></span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <br />
                <pagination :lastPage="pages" @change="fetch" />
              </div>
              <div v-else class="qt-block-ui relative" style="padding: 120px" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <edit-provider-modal
      id="edit-provider-modal"
      @sent="fetch"
    ></edit-provider-modal>
    <create-provider-modal
      id="create-provider-modal"
      @sent="fetch"
    ></create-provider-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
import ProviderService from '@/services/admin/provider.service';
import CreateProviderModal from '@/components/providers/CreateProviderModal.vue';
import EditProviderModal from '@/components/providers/EditProviderModal.vue';
import Pagination from '@/components/Pagination.vue';
import Swal from 'sweetalert2';

export default {
  name: 'Providers',
  components: {
    PageHeader,
    EditProviderModal,
    CreateProviderModal,
    Pagination,
  },
  data() {
    return {
      fetched: false,
      providers: [],
      form: {
        name: '',
        page: 1,
      },
      pages: 1,
      updating: false,
    };
  },
  created() {
    this.fetch(1);
  },
  methods: {
    fetch(page = 1) {
      this.form.page = page;
      this.fetched = false;
      ProviderService.getProviders(this.form).then(
        (response) => {
          this.fetched = true;
          this.providers = response.data;
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    remove(id) {
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text: this.$t('adm-component.delet-text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('warnings.btn-y-delete'),
      }).then((result) => {
        if (result.isConfirmed) {
          this.fetched = false;
          ProviderService.deleteProvider(id).then(
            () => {
              this.fetch(this.form.page);
              Swal.fire(`${this.$t('generic-str.deleted')}!`, this.$t('adm-component.delet-done'), 'success');
            },
            (error) => {
              this.content = error;
            },
          );
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.btn-remove {
  color: #fff;
  margin-bottom: 5px;
}
.btn-new {
  margin-bottom: 5px;
}
.color-white {
  color: #fff;
}
.form-search {
  float: right;
  width: 100%;
}
.form-search button {
  border: solid 1px #ccc;
  border-left: 0px;
  text-align: center;
  border-radius: 0px 10px 10px 0px;
  height: 35px;
}
.form-search button i {
  font-size: 16px;
}
.w-100 {
  width: 100% !important;
}
</style>
